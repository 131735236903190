import { template as template_0ee801f4759744238e79ef036fc6f200 } from "@ember/template-compiler";
const WelcomeHeader = template_0ee801f4759744238e79ef036fc6f200(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
