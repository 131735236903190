import { template as template_3411ec9e110b4130bc36f13ef5ebd0fb } from "@ember/template-compiler";
const FKControlMenuContainer = template_3411ec9e110b4130bc36f13ef5ebd0fb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
